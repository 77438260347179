
import { defineComponent, ref, computed } from '@vue/composition-api';
import { Field } from '../index';
// deugged molecules using AI

interface AppProviders {
  google: boolean;
  apple: boolean;
  web: boolean;
}
interface OrganizerSetupOKR {
  instructions: string;
  providers: AppProviders;
}

interface AppSubmission {
  google: string;
  apple: string;
  web: string;
}
interface ParticipantOKR {
  submission: AppSubmission;
  description: string;
}

interface OKRField extends Field {
  value: ParticipantOKR;
  setup: OrganizerSetupOKR;
}

export default defineComponent({
  name: 'MDownloadApp',
  props: {
    fetchProgram: {
      required: false,
      type: [Object, Function],
      default: () => {}
    },
    adkType: {
      required: false,
      type: String,
      default: ''
    },
    userType: {
      required: true,
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(_props, _ctx) {
    const programDocument: any = computed({
      get: () => _props.value,
      set: newVal => {
        _ctx.emit('input', newVal);
      }
    });

    const adkIndex = computed(() => {
      const indx = programDocument.value?.data.adks?.findIndex(adk => {
        if (adk) {
          return adk.name === 'Download App';
        }
        return false;
      });
      return indx;
    });

    const fieldIndex = computed(() => {
      const Fdindx = programDocument.value?.data?.adks?.[adkIndex.value]?.fields?.findIndex(
        field => {
          if (field.name) {
            return field.name === 'm-download-app';
          }
          return false;
        }
      );
      return Fdindx;
    });

    const fieldValue = computed<OKRField>({
      get: () => programDocument.value?.data?.adks?.[adkIndex.value]?.fields[fieldIndex.value],
      set: newVal => {
        if (fieldValue.value)
          programDocument.value.data.adks[adkIndex.value].fields[fieldIndex.value] = newVal;
      }
    });

    const userTypeValue = computed(() => {
      if (
        _props.userType === 'organizer' &&
        _ctx.root.$route.name !== 'Monitor' &&
        !_props.isPreview
      ) {
        return 'organizer';
      }
      if (_props.isPreview) return 'participant';
      if (_props.isVisitor) return 'organizer';
      return 'participant';
    });

    const userIsEmployer = ref(userTypeValue.value === 'organizer');

    const participantData = computed(() => fieldValue.value.value);
    const updateParticipantData = newVal => {
      fieldValue.value.value = newVal;
    };

    const organizerData = computed(() => fieldValue.value.setup);
    const updateOrganizerData = newVal => {
      fieldValue.value.setup = newVal;
    };

    const providers = computed(() => {
      if (!organizerData.value.providers) {
        return {
          google: true,
          apple: true,
          web: true
        };
      }
      return organizerData.value.providers;
    });

    const updateProviders = (newProviders: AppProviders) => {
      organizerData.value.providers = newProviders;
    };

    const submission = computed(() => {
      if (!participantData.value.submission) {
        return {
          google: '',
          apple: '',
          web: ''
        };
      }
      return participantData.value.submission;
    });

    const updateSubmission = (newSubmission: AppSubmission) => {
      participantData.value.submission = newSubmission;
    };

    const urlRegex =
      /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/;

    return {
      userIsEmployer,
      participantData,
      updateParticipantData,
      organizerData,
      updateOrganizerData,
      providers,
      updateProviders,
      submission,
      updateSubmission,
      urlRegex
    };
  }
});
