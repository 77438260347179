var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.userIsEmployer)?_c('div',[_c('v-container',[_c('h4',[_vm._v("Allow submissions through")]),_c('v-checkbox',{attrs:{"value":_vm.providers.google,"label":"Google Play Store"},on:{"input":function($event){return _vm.updateProviders(Object.assign({}, _vm.providers, {google: $event}))}}}),_c('v-checkbox',{attrs:{"value":_vm.providers.apple,"label":"Apple App Store"},on:{"input":function($event){return _vm.updateProviders(Object.assign({}, _vm.providers, {apple: $event}))}}}),_c('v-checkbox',{attrs:{"value":_vm.providers.web,"label":"Website/Web Application"},on:{"input":function($event){return _vm.updateProviders(Object.assign({}, _vm.providers, {web: $event}))}}}),_c('v-divider',{staticStyle:{"margin-top":"1rem","margin-bottom":"2rem"}}),_c('validation-provider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Enter instructions for intern app submission","rounded":"","outlined":"","placeholder":"What should be in the app?","error-messages":errors},on:{"input":function($event){return _vm.updateOrganizerData(Object.assign({}, _vm.organizerData, {instructions: $event}))}},model:{value:(_vm.organizerData.instructions),callback:function ($$v) {_vm.$set(_vm.organizerData, "instructions", $$v)},expression:"organizerData.instructions"}})]}}],null,false,3256020655)})],1)],1):_c('div',[_c('v-container',[_c('h3',{staticStyle:{"margin-bottom":"0.4rem"}},[_vm._v("Employer Instructions")]),_c('p',[_vm._v(_vm._s(_vm.organizerData.instructions))]),_c('v-divider',{staticStyle:{"margin-top":"1.5rem","margin-bottom":"1.5rem"}}),_c('h3',{staticStyle:{"margin-bottom":"0.4rem"}},[_vm._v("Your Submission")]),(_vm.providers.google && _vm.providers.apple && _vm.providers.web)?_c('div',[_c('p',[_vm._v(" Your app should work on at least one of the following platforms: Android, iOS, or the browser. ")])]):(_vm.providers.google && _vm.providers.apple)?_c('div',[_c('p',[_vm._v("You will be building a mobile app which should work on Android and/or iOS.")])]):(_vm.providers.google && _vm.providers.web)?_c('div',[_c('p',[_vm._v("Your app should work on Android and/or the browser.")])]):(_vm.providers.apple && _vm.providers.web)?_c('div',[_c('p',[_vm._v("Your app should work on iOS and/or the browser.")])]):(_vm.providers.google)?_c('div',[_c('p',[_vm._v("You will be building an Android app.")])]):(_vm.providers.apple)?_c('div',[_c('p',[_vm._v("You will be building an iOS app.")])]):(_vm.providers.web)?_c('div',[_c('p',[_vm._v("You will be building a website or web application.")])]):_vm._e(),(_vm.providers.google)?_c('div',[_c('validation-provider',{attrs:{"rules":{ regex: _vm.urlRegex, required: false }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.submission.google,"rounded":"","outlined":"","label":"Google Play Store URL","placeholder":"Google Play Store URL","error-messages":errors},on:{"input":function($event){return _vm.updateSubmission(Object.assign({}, _vm.submission, {google: $event}))}}})]}}],null,false,2729814980)})],1):_vm._e(),(_vm.providers.apple)?_c('div',[_c('validation-provider',{attrs:{"rules":{ regex: _vm.urlRegex, required: false }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.submission.apple,"rounded":"","outlined":"","label":"Apple App Store URL","placeholder":"Apple App Store URL","error-messages":errors},on:{"input":function($event){return _vm.updateSubmission(Object.assign({}, _vm.submission, {apple: $event}))}}})]}}],null,false,1645333252)})],1):_vm._e(),(_vm.providers.web)?_c('div',[_c('validation-provider',{attrs:{"rules":{ regex: _vm.urlRegex, required: false }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.submission.web,"rounded":"","outlined":"","label":"Website/Web Application URL","placeholder":"Website/Web Application URL","error-messages":errors},on:{"input":function($event){return _vm.updateSubmission(Object.assign({}, _vm.submission, {web: $event}))}}})]}}],null,false,3535009604)})],1):_vm._e(),_c('validation-provider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"rounded":"","outlined":"","label":"App Description","placeholder":"What's in your app?","error-messages":errors},on:{"input":function($event){return _vm.updateParticipantData(Object.assign({}, _vm.participantData, {description: $event}))}},model:{value:(_vm.participantData.description),callback:function ($$v) {_vm.$set(_vm.participantData, "description", $$v)},expression:"participantData.description"}})]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }