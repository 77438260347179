import activity from './m-download-app.vue';

export default activity;

export const componentRegistrationData = {
  name: 'Download App',
  icon: 'mdi-star',
  description: 'Submit a Google Play, App Store, and/or Web app for your employer to download',
  status: true,
  setupRequired: true,
  fields: [
    {
      roles: ['employer', 'participant'],
      name: 'm-download-app',
      type: 'molecule',
      position: 0,
      preset: {},
      setup: {
        instructions: '',
        providers: {
          google: true,
          apple: true,
          web: true
        }
      },
      value: {
        submission: {
          google: '',
          apple: '',
          web: ''
        },
        description: ''
      }
    },
    {
      roles: ['employer', 'participant'],
      name: 'm-save-adk-only',
      type: 'molecule',
      position: 1,
      preset: {},
      setup: {},
      value: {}
    }
  ],
  styling: {
    borderColor: '#74b72e'
  }
};
